'use client'

import { useRouter } from "next/navigation"
import { ChevronRightIcon } from "@radix-ui/react-icons"

import { cn } from "@/lib/utils"
import { Button } from "@/components/ui/button"
import { DEMO_ROUTE } from "@/app/routes"
import { getAssetUrl, LOTTIE, SPECIFICITY, TYPE } from "@/lib/assets"
import { LottiePlayer } from "./lottie/player"

const Heading = () => {
  return (
    <div
      className={cn(
        "w-full flex flex-col justify-start items-center gap-y-4"
      )}
    >
      <h2
        className={cn(
          "w-full text-center text-3xl text-txtGrey25 leading-125 font-medium",
          "md:text-[calc(20px+2.1567vw)] md:leading-120 md:-tracking-[0.72px]",
          "xl:text-6xl xl:leading-120 xl:-tracking-[1.2px]"
        )}
      >
        Stop Fraud and Boost Growth Today
      </h2>
      <p
        className={cn(
          "w-full text-center text-sm text-txtGrey25 leading-140",
          "md:text-[calc(7px+1.1567vw)] md:leading-150 xl:text-lg xl:leading-150"
        )} 
      >
        Book a call with our Fraud Prevention Experts
      </p>
    </div>
  )
}


const Container = () => {
  const router = useRouter()

  const clickHandler = () => {
    router.push(DEMO_ROUTE)
  }
  return (
    <div
      className={cn(
        "w-full relative z-40 box-border flex flex-col justify-start items-center gap-y-8 px-8 py-12",
        "md:gap-y-20 xl:py-24 xl:gap-y-32"
      )}
      style={{
        transform: 'translateZ(10px)'
      }}
    >
      <Heading />
      <Button
        onClick={clickHandler}
        className={cn(
          "h-fit px-8 py-2 flex justify-center items-center gap-x-2 bg-white shadow-footetdemobtn",
          "md:min-w-[304px] md:py-3 xl:py-4 xl:w-[378px]", "hover:shadow-footerDemoBtnHover hover:bg-white",
          "rounded-sm md:rounded-lg"
        )}
      >
        <span
          className="text-sm font-medium leading-115 text-txtGrey700 md:text-base md:leading-125 xl:text-lg xl:font-semibold xl:leading-135"
        >Book a Demo</span>
        <ChevronRightIcon
          className="w-4 h-4 text-txtGrey700 md:w-5 md:h-5"
        />
      </Button>
    </div>
  )
}

const XlGradientLine = ({
  className
}) => {
  return (
    <div
      className={cn(
        "w-[55px] md:w-[65px] xl:w-[92px] h-[815px] bg-footerLine absolute blur-[24px] md:blur-[40px] xl:blur-[50px] z-10",
        className
      )}
    >

    </div>
  )
}

const XlGradient = () => {
  return (
    <div
      className="absolute w-full aspect-1 rounded-full bg-footerRadient xl:bg-xlfinalgradient z-10 left-0 top-[60%] md:top-1/2"
    >
    </div>
  )
}

const LottieContainer = () => {
  const animFile = getAssetUrl(SPECIFICITY.NONE, TYPE.lottie, LOTTIE.lineMotion)
  return (
    <div
      className="w-full h-full absolute z-0 top-0 left-0 bottom-0"
    >
      <LottiePlayer url={animFile} />
    </div>
  )
}

export const FinalSection = () => {
  
  return (
    <section
      className={cn(
        "box-border w-screen bg-footer-bg overflow-hidden",
        "border-b footerTopBorder relative z-0"
      )}
    >
      <LottieContainer />
      <div
        className={cn(
          "relative z-20 w-full box-border px-4 py-12 md:w-[calc(100%-40px)] md:mx-5 md:px-5 md:py-16",
          "xl:w-[1242px] xl:mx-auto xl:py-24"          
        )}
        style={{
          transform: 'translateZ(30px)'
        }}
      >
        <XlGradientLine className={"h-[640px] -top-[185px] right-[420px] xl:blur-[40px] rotate-45 hidden xl:block"} />
        <XlGradientLine className={"h-[640px] -top-[185px] left-[420px] -rotate-45 xl:blur-[40px] hidden xl:block"} />
        <XlGradientLine className={cn(
          "left-[60px] -top-[55px] -rotate-45 h-[345px] md:h-[410px] xl:h-[815px]",
          "md:left-[85px] md:-top-[60px] xl:-top-[215px] xl:left-[220px]"
        )} />
        <XlGradientLine className={cn(
          "right-[60px] -top-[55px] rotate-45 h-[345px] md:h-[410px]",
          "md:right-[85px] md:-top-[60px] xl:-top-[215px] xl:right-[220px] xl:h-[815px]"
        )} />
        <XlGradient />
        <Container />
      </div>
    </section>
  )
}